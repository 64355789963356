import { shortNumberFormat } from 'soapbox/utils/numbers.tsx';

interface ICounter {
  /** Number this counter should display. */
  count: number;
  /** Optional max number (ie: N+) */
  countMax?: number;
}

/** A simple counter for notifications, etc. */
const Counter: React.FC<ICounter> = ({ count, countMax }) => {
  return (
    <span className='mt-0.5 flex h-5 min-w-5 max-w-6 items-center justify-center rounded-full bg-secondary-500 text-xs font-medium text-white ring-2 ring-white black:ring-black dark:ring-gray-800'>
      {shortNumberFormat(count, countMax)}
    </span>
  );
};

export default Counter;
